import Head from "next/head";

/**
 * Page meta data component.
 */
export function PageMeta({
  children,
  description,
  imageUrl,
  noIndex,
  siteName = "Showspace",
  title,
  url,
}: {
  /* Passes custom meta tags into head tag. */
  children?: React.ReactNode;
  /* Adds page meta description tags. */
  description?: string;
  /* Adds page meta thumbnail image tags. */
  imageUrl?: string;
  /* Adds noindex meta tag to block robots from indexing page. */
  noIndex?: boolean;
  /* Adds page meta site name tags. */
  siteName?: string;
  /* Adds page meta title tags. */
  title?: string;
  /* Adds page url tags. */
  url?: string;
}) {
  return (
    <Head>
      {noIndex && <meta name="robots" content="noindex" />}
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}
      {url && (
        <>
          <link rel="canonical" href={url} />
          <meta property="og:url" content={url} />
          <meta name="twitter:url" content={url} />
        </>
      )}
      {description && (
        <>
          <meta property="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} />
          <meta name="twitter:image" content={imageUrl} />
        </>
      )}
      <meta property="og:site_name" content={siteName} />
      <meta property="og:type" content="website" />
      <meta content="summary_large_image" name="twitter:card" />
      {children}
    </Head>
  );
}
